import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/Layout';
import { RecentPostGrid } from '../components/PostGrid';

const Explore = () => {
  const response = useStaticQuery(getPosts);
  const posts = response.allMdx.edges;
  return (
    <Layout>
      <SEO
        title="Explore Topics"
        keywords={[`thecodesmith`, `software`, `development`, `tutorials`]}
      />
      <RecentPostGrid heading={'recent posts'} posts={posts}></RecentPostGrid>
    </Layout>
  );
};

const getPosts = graphql`
  query getPosts {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          frontmatter {
            author
            date(formatString: "MMMM Do, YYYY")
            slug
            title
            topic
          }
        }
      }
    }
  }
`;

export default Explore;
